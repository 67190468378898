import React, { ReactElement } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'

function Page(_props) {
  return (
    <Layout>
      <h1>Om Kiropraktikk</h1>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <div className="md:col-span-3">
          <p>
            Kiropraktikk betyr å behandle med hendene. En Kiropraktor er ekspert på å behandle muskel-, nerve- og
            skjelettplager i hele bevegelsesapparatet.
          </p>
          <p>
            Målet med behandlingen er å finne årsaken til problemet ditt, som nødvendigvis ikke sitter der det gjør
            vondt. Dette for å oppnå langsiktig resultat. Ved å gjenopprette fullgod funksjon i bevegelsesapparatet
            fører dette nesten alltid til smertelindring og oftest smertefrihet.
          </p>
        </div>
        <StaticImage
          src="../images/shutterstock_233854501_web.png"
          alt="Om kiropraktik"
          layout="fullWidth"
          aspectRatio={1.5}
          // height={200}
          width={150}
          objectFit="cover"
          className="md:col-span-2 mx-auto w-full max-w-sm rounded-lg shadow-md"
          imgClassName="rounded-lg"
        />
      </div>

      <p>
        Hvert ledd i kroppen er en funksjonell enhet som må fungere normalt for at vi skal ha optimal motorisk kontroll.
        Et ledd som ikke fungerer normalt kan ha redusert bevegelighet. Kroppens naturlige respons til dette er øket
        bevegelighet et annet sted i bevegelsesapparatet. Dette vil videre kunne lede til smerte lokalt ved bevegelse.
      </p>

      <p>
        På sikt vil området med øket bevegelighet gjennomgå strukturelle forandringer, fordi det blir utsatt for mer
        belastning enn det er designet for å tåle. Dersom dette ikke blir korrigert, kan degenerativ leddlidelse,
        degenerativ skivelidelse eller skiveprolaps bli et faktum. For å forebygge fremtidige muskeloskeletale lidelser,
        er det derfor viktig at samtlige bevegelsesenheter i kroppen fungerer optimalt.
      </p>
      <p>
        Kiropraktisk behandling er veldokumentert sikker og effektiv med langt lavere risiko for alvorlige
        komplikasjoner enn tradisjonell medisinsk behandling for tilsvarende lidelser. Kiropraktoren er primærkontakt og
        det er ikke nødvendig med henvisning fra lege. Behandlingen er individuelt tilpasset, og vil med det på bakgrunn
        av det pasienten forteller gi en undersøkelse og starte et behandlingsopplegg.
      </p>
      <p>
        En Kiropraktor har bred kunnskap og kan henvise pasienten videre til røntgen-, CT-, MR- undersøkelse eller
        spesialist i de tilfeller hvor det er hensiktsmessig. Kiropraktoren kan også sykemelde pasienten i opp til 12
        uker. (NAV/HELFO/NKF)
      </p>
      <p>
        Du trenger ikke henvisning fra lege for å få behandling hos kiropraktor, og deretter rett til stønad fra HELFO.
        Kiropraktoren har mulighet til å henvise deg videre til legespesialist, røntgen og fysioterapi. Kiropraktoren
        kan også gi sykemelding i en periode inntil 12 uker.
      </p>
    </Layout>
  )
}

export default Page
